import { useEffect, useState } from "react";
import { Button, Image } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faInfoCircle, faTimes } from "@fortawesome/free-solid-svg-icons"
import { Modal } from "react-bootstrap";
import paso1 from "../static/img/tutorial/paso-1-ios.png"
import paso2 from "../static/img/tutorial/paso-2-ios.png"
import paso3 from "../static/img/tutorial/paso-3-ios.png"

const TutorialModal = ({ show, handleShow, handleClose }) => {
  
  return (
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#1C3664" }}><FontAwesomeIcon icon={faInfoCircle} style={{ marginRight:"10px" }}/> Instalación en dispositivos de Apple</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ol>
            <li>
              <p>Presionar el botón de compartir en el navegador Safari.</p> 
              <Image
                className="d-block w-100"
                src={paso1}
                alt="First slide"
              />
            </li>            
            <li style={{ marginTop:"20px" }}>
              <p>Presionar el botón “Añadir a la pantalla de inicio”.</p>
              <Image
                className="d-block w-100"
                src={paso2}
                alt="First slide"
              />
            </li>
            <li style={{ marginTop:"20px" }}>
              <p>Si es necesario, modificar el nombre del app y presionar “Añadir”.</p>
              <Image
                className="d-block w-100"
                src={paso3}
                alt="First slide"
              />
            </li>
          </ol>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleClose}><FontAwesomeIcon icon={faTimes} style={{ marginRight:"10px" }}/> Cerrar</Button>
        </Modal.Footer>
    </Modal>
  )
}

const InstallPWAButtonAndroid = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
      const handler = e => {
        e.preventDefault();
        console.log("we are being triggered :D");
        setSupportsPWA(true);
        setPromptInstall(e);
      };
      window.addEventListener("beforeinstallprompt", handler);
  
      return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = evt => {
      evt.preventDefault();
      if (!promptInstall) {
        return;
      }
      promptInstall.prompt();
    };

    if (!supportsPWA) {
      return null;
    }

  return (
      <Button variant="light" onClick={ onClick } style={{ minWidth:'150px', color: "#1C3664", fontWeight: "bold", backgroundColor: "#E8E8E8" }}>
          <FontAwesomeIcon icon={faDownload} style={{ marginRight:"10px" }}/>
          Instalar
      </Button>
  )
}

const InstallPWAIos = () => {
  const [show, setShow] = useState(false);
  
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <Button variant="light" onClick={ handleShow } style={{ minWidth:'150px', color: "#1C3664", fontWeight: "bold", backgroundColor: "#E8E8E8" }}>
          <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight:"10px" }}/>
          Instalar
      </Button>
      <TutorialModal show={show} handleShow={handleShow} handleClose={handleClose}/>
    </>
  )
}

const InstallPWAButton = () => {
  const [ios, setIos] = useState(false)
  const [standAlone, setStandAlone] = useState(false)
  useEffect(() => {
    setIos(() => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test( userAgent );
    })
    if(ios) {
      setStandAlone(() => ('standalone' in window.navigator) && (window.navigator.standalone));
    }
  })
  
  if(!ios) {
    return (
      <InstallPWAButtonAndroid />
    )
  } else {
    if(!standAlone) {
      return (
        <InstallPWAIos />
      )
    }
  }
  return null
}

export default InstallPWAButton