import { Button, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faSignOutAlt } from "@fortawesome/free-solid-svg-icons"
import "./NavigationBar.scss"

const NavigationBar = ({ previousRoute, previousRouteLabel, logoutCallback }) => {
    
    const renderTitle = () => {
        if(!previousRoute && !previousRouteLabel) {
            return (
                <Navbar.Brand className="bold">SIIBCI</Navbar.Brand>
            )
        }
        return null
    }

    const renderPreviousRoute = () => {
        if(previousRoute) {
            return (
                <Nav.Item className="navbar--item">
                    <Link to={previousRoute}>
                        <FontAwesomeIcon icon={ faChevronLeft } />
                    </Link>
                </Nav.Item>
            )
        }

        return null
    }

    const renderPageTitle = () => {
        if(previousRouteLabel) {
            return (
                <Nav.Item className="navbar--item">
                    {previousRouteLabel}
                </Nav.Item>
            )
        }
    }

    return (
        <Navbar>
            { renderTitle() }
            <Nav className="mr-auto">
                { renderPreviousRoute() }
                { renderPageTitle()}
            </Nav>
            <Button variant="link" onClick={ logoutCallback } className="navbar--item">
                <FontAwesomeIcon icon={faSignOutAlt} />
            </Button>
        </Navbar>
        
    )
    
}

export default NavigationBar;