import { useState } from "react"
import { Col, Form, Row, Button, Modal } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons"
import InstallPWAButton from "./InstallPWA";
import LoginLayout from "./layout/LoginLayout";

const LoginForm = ({ loginCallback, forgotPassCallback }) => {
    const [userName, onChangeUserName] = useState('')
    const [password, onChangePassword] = useState('')
    const [showModal, setShowModal] = useState(false)

    const handleClose = () => setShowModal(false)

    const submit = async () => {
        const loginResult = await loginCallback(userName, password)
        
        if(!loginResult) {
            //TODO mensaje de error
            setShowModal(true)
        }
    }

    return (        
        <LoginLayout>
            <Row className="justify-content-center align-items-center">
                <Modal show={showModal} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Autenticación incorrecta, por favor revise sus credenciales...
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Cerrar
                    </Button>
                    </Modal.Footer>
                </Modal>
                <Col xs={10} md={6} style={{ backgroundColor: "white", padding:"20px", borderRadius:"10px" }}>
                    <Col xs={12}>
                        <Form className="form">
                            <h3 className="text-center" style={{ color:"#1C3664", fontWeight:"bold", marginBottom:"40px" }}>Bienvenido al SIIBCI</h3>
                            <Form.Group controlId="username">
                                <Form.Control placeholder="Usuario" value={ userName } onChange={ (event) => { onChangeUserName(event.target.value) } }/>
                                <Form.Text className="text-muted">
                                    Su nombre de usuario es el correo electrónico institucional
                                </Form.Text>
                            </Form.Group>

                            <Form.Group controlId="password" style={{ marginBottom:'0' }}>
                                <Form.Control type="password" placeholder="Contraseña" value={ password } onChange={ (event) => { onChangePassword(event.target.value) } }/>
                            </Form.Group>
                            <div className="text-right mb-3">
                                    <Button variant="link" style={{ color:"#1C3664" }} onClick={forgotPassCallback}>
                                        ¿Olvidó su contraseña?
                                    </Button>
                            </div>
                            <div style={{ textAlign:"center" }}>
                                <div style={{ marginBottom:"20px" }}>
                                    <Button variant="light" onClick={() => { submit() }} style={{ minWidth:'150px', color: "#1C3664", fontWeight: "bold", backgroundColor: "#E8E8E8" }}>
                                        <FontAwesomeIcon icon={faSignInAlt} style={{ marginRight:"10px" }}/>
                                        Login
                                    </Button>
                                </div>                                    
                                <div>
                                    <InstallPWAButton />
                                </div>
                            </div>
                        </Form>
                    </Col>
                </Col>
            </Row>
        </LoginLayout>
    )
} 

export default LoginForm