import { get } from './fetch';

export const getCountryList = (search = null) => {
    if(search !== '') {
        return get(`/api/paises/list?search=${search}&_format=json`)
    }
    return get('/api/paises/list?_format=json')
}

export const getCountry = (id) => {
    return get(`/api/paises/${id}?_format=json`)
}