import { get } from './fetch';

export const getParagraphList = (array) => {

    var arrayString = ''

    if(array.length > 1) {
        for (let i = 0; i < array.length; i++) {
            if(i < array.length - 1) {
                arrayString = arrayString + array[i] + ','
            } else {
                arrayString = arrayString + array[i]
            }                 
        }
    } else {
        arrayString = array[0]
    }
    
    //console.log("AS: " + arrayString)
    
    return get(`/api/paises/relaciones/${arrayString}`)
}
