import { useState, useEffect } from "react";
import { Col, Image, ListGroup, Row } from "react-bootstrap"
import { Link, useParams } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import CountryPageTitle from "../../components/layout/CountryPageTitle";
import { getCountry } from "../../api/countries";
import ficha from "../../static/img/logos/ficha.png";
import cooperacion from "../../static/img/logos/cooperacion.png";
import economic from "../../static/img/logos/economic.png";
import juridicos from "../../static/img/logos/juridicos.png";
import politica from "../../static/img/logos/politica.png";
import "./Menu.scss"

const Menu = ({ setCurrentCountryCallback, setPreviousRoute, setPreviousRouteLabel }) => {
    var { id } = useParams()

    const [country, setCountry] = useState()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getCountry(id)
        .then((res) => {            
            setCountry(res[0])
            setCurrentCountryCallback(res[0])
            setLoading(false)
            setPreviousRoute('/')
            setPreviousRouteLabel('Menú')
        })
        .catch(error => {
            console.log(error)
        })
    }, [])

    if(loading) {
        return (
            <LoadingSpinner />
        )
    }

    const LinkInstrumentosJuridicos = ( field ) => {   
        if(field.length > 0) {
            return (
                <ListGroup.Item>
                    <a target="_blank" rel="noopener noreferrer" href={field[0].uri}>
                        <Row>
                            <Col xs={2} className="menu-list--icon-icon">
                                <Image src={juridicos} rounded />
                            </Col>                            
                            <Col className="menu-list--icon-description">
                                <h5>Instrumentos Jurídicos</h5>
                                <h6>Encuentre acá el detalle de los Instrumentos Jurídicos</h6>
                            </Col>
                        </Row>
                    </a>
                </ListGroup.Item>
            )
        }
        return null
    }   

    return (
        <>
            <CountryPageTitle titleField={country.title} imageField={country.field_image} />
            <ListGroup variant="flush">
                <ListGroup.Item>
                    <Link to={`/country/${id}/file`}>
                        <Row>
                            <Col xs={2} className="menu-list--icon-icon">
                                <Image src={ficha} rounded />
                            </Col>                            
                            <Col xs={10} className="menu-list--icon-description">
                                <h5>Ficha país</h5>
                                <h6>Encuentre acá el detalle de la ficha país</h6>
                            </Col>
                        </Row>
                    </Link>
                </ListGroup.Item>
                { LinkInstrumentosJuridicos(country.field_instrumentos_juridicos) }
                <ListGroup.Item>
                    <Link to={`/country/${id}/policies`}>
                        <Row>
                            <Col xs={2} className="menu-list--icon-icon">
                                <Image src={politica} rounded />
                            </Col>                            
                            <Col xs={10} className="menu-list--icon-description">
                                <h5>Política Exterior</h5>
                                <h6>Encuentre acá el detalle de Política Exterior</h6>
                            </Col>
                        </Row>
                    </Link>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Link to={`/country/${id}/diplomacy`}>
                        <Row>
                            <Col xs={2} className="menu-list--icon-icon">
                                <Image src={economic} rounded />
                            </Col>                            
                            <Col xs={10} className="menu-list--icon-description">
                                <h5>Diplomacia Económica</h5>
                                <h6>Encuentre acá el detalle de Diplomacia Económica</h6>
                            </Col>
                        </Row>
                    </Link>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Link to={`/country/${id}/cooperation`}>
                        <Row>
                            <Col xs={2} className="menu-list--icon-icon">
                                <Image src={cooperacion} rounded />
                            </Col>                            
                            <Col xs={10} className="menu-list--icon-description">
                                <h5>Cooperación Internacional</h5>
                                <h6>Encuentre acá el detalle de Cooperación Internacional</h6>
                            </Col>
                        </Row>
                    </Link>
                </ListGroup.Item>
            </ListGroup>
        </>
    )
}

export default Menu