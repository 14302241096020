import { post } from './fetch';

export const login = (username, password) => {

    var loginEndpoint = `/api/v1/token?_format=hal_json`
    var userInfo = {
        username: username,
        password: password,
        jwt: ''
    }

    return post(loginEndpoint, userInfo);
}

export default login;