
import { useEffect } from "react";
import { Container } from "react-bootstrap"
import AccordionItem from "../../components/Accordions";
import CurrentTopics from "../../components/CurrentTopics"
import CountryPageTitle from "../../components/layout/CountryPageTitle"
import Meetings from "../../components/Meetings"
import Nominations from "../../components/Nominations"
import { parseParagraphField } from "../../util/FieldParsers"
import { DownloadPDFField, MultiLineLabelTextField, SingleLineLabelTextField } from "../../util/FieldUtility"

const Policies = ({ country, setPreviousRoute, setPreviousRouteLabel }) => {
    useEffect(() => {
        setPreviousRoute(`/country/${country.nid[0].value}`)
        setPreviousRouteLabel('Política exterior')
    }, [])  
    
    return (
        <>
            <CountryPageTitle titleField={country.title} imageField={country.field_image} />
            <Container>
                <SingleLineLabelTextField label="Fecha de est. de relaciones diplomáticas" field={country.field_field_fecha_rel_dip} />
                <MultiLineLabelTextField label="Mecanismo de diálogo" field={country.field_mecanismo_dialogo} />
                <DownloadPDFField label="Arch. mecanismo de diálogo" field={country.field_instrumento_mec_dialogo} country={country}/>
                <Meetings meetingsIds={ parseParagraphField(country.field_reuniones_realizadas) } />
                <Nominations nominationIds={ parseParagraphField(country.field_candidaturas) } country={country}/>
                <CurrentTopics topics={ country.field_temas_actuales }/>
                <MultiLineLabelTextField label="Acuerdos en negociación" field={country.field_acuerdos_negociacion} />
                <AccordionItem title="DESOMA" bg="filled">
                    <MultiLineLabelTextField label="Temas de interés" field={country.field_desoma_temas_interes} />
                    <MultiLineLabelTextField label="Grupos afines" field={country.field_desoma_grupos_afines} />
                </AccordionItem>
                <AccordionItem title="DDHH" bg="filled">
                    <MultiLineLabelTextField label="Temas de interés" field={country.field_ddhh_temas_interes} />
                    <MultiLineLabelTextField label="Grupos afines" field={country.field_ddhh_grupos_afines} />
                </AccordionItem>
                <AccordionItem title="Desarme, terrorismo y crimen organizado" bg="filled">
                    <MultiLineLabelTextField label="Temas de interés" field={country.field_desarme_temas_interes} />
                    <MultiLineLabelTextField label="Temas sensibles" field={country.field_desarme_temas_sensibles} />
                    <MultiLineLabelTextField label="Grupos afines" field={country.field_desarme_grupos_afines} />
                </AccordionItem>
                <AccordionItem title="Organismos internacionales" bg="filled">
                    <MultiLineLabelTextField label="Observador" field={country.field_organismos_observador} />
                    <MultiLineLabelTextField label="Miembro" field={country.field_organismos_miembro} />
                </AccordionItem>
                <AccordionItem title="SICA" bg="filled">
                    <MultiLineLabelTextField label="Temas relevantes" field={country.field_sica_temas_relevantes} />
                    <MultiLineLabelTextField label="OEA, temas relevantes" field={country.field_oea_temas_relevantes} />
                    <MultiLineLabelTextField label="CEPAL, temas relevantes" field={country.field_cepal_temas_relevantes} />
                    <MultiLineLabelTextField label="CELAC, temas relevantes" field={country.field_celac_temas_relevantes} />
                    <MultiLineLabelTextField label="UE, temas relevantes" field={country.field_ue_temas_relevantes} />
                    <MultiLineLabelTextField label="AEC, temas relevantes" field={country.field_aec_temas_relevantes} />
                </AccordionItem>
            </Container>
        </>
    )
}

export default Policies