import { useState } from "react";
import { ListGroup } from "react-bootstrap";
import accordionCallback from "../util/AccordionCallbacks"
import SubEntityListItem from "../util/SubEntityList";
import AccordionItem from "./Accordions"
import DetailModal from "./DetailModal";

const Meetings = ({ meetingsIds }) => {
    const [meetings, setMeetings] = useState(null)
    const [hasLoaded, setHasLoaded] = useState(false)
    //Para modal
    const [showModal, setShowModal] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(null)

    const handleShowModal = (index) => {
        setSelectedIndex(index)
        setShowModal(true)
    }

    const handleCloseModal = () => {
        setSelectedIndex(null)
        setShowModal(false)
    }

    const renderInnerAccordion = () => {
        if(!meetings) {
            if(!hasLoaded) {
                return (
                    <h5>Loading...</h5>
                )
            } else {
                return (
                    <h5>No hay reuniones para desplegar</h5>
                )
            }
            
        }

        return (
            <ListGroup variant="flush">
                {meetings.map((meeting, i) => {
                    return (
                        <SubEntityListItem entity={meeting} key={i} text={ meeting.field_nombre[0].value } handleShowModal={ handleShowModal } itemIndex={i}/>
                    )
                })}
            </ListGroup>
        )
    }

    return (
        <>
            <DetailModal showModal={ showModal } handleClose={ handleCloseModal } entityArray={ meetings } selectedIndex={selectedIndex} setIndex={ setSelectedIndex }/>
            <AccordionItem title="Reuniones realizadas" bg="filled" callback={accordionCallback(meetingsIds, meetings, setMeetings, setHasLoaded)} >
                { renderInnerAccordion() }
            </AccordionItem>
        </>
    )
}

export default Meetings