import { Modal, ModalFooter } from "react-bootstrap"
import { DownloadPDFField, MultiLineLabelTextField, SingleLineLabelDateField, SingleLineLabelTextField } from "../util/FieldUtility"
import ModalScrolling from "./layout/ModalScrolling"


const DisplayNominations = ({ nomination }) => {
    return (
        <>
            <SingleLineLabelTextField label="Nombre de la candidatura" field={nomination.field_nombre} />
            <SingleLineLabelTextField label="Período" field={nomination.field_periodo} />
            <SingleLineLabelTextField label="Fecha de elección" field={nomination.field_fecha_de_eleccion} />                       
            <MultiLineLabelTextField label="Información sobre solicitud de apoyo o propuesta de intercambio" field={nomination.field_inf} />
        </>
    )
}

const DisplayMeetings = ({ meeting }) => {
    return (
        <>
            <SingleLineLabelTextField label="Lugar" field={meeting.field_lugar} />
            <SingleLineLabelTextField label="Fecha" field={meeting.field_fecha} />            
            <MultiLineLabelTextField label="Autoridades presentes" field={meeting.field_autoridades_presentes} />
            <MultiLineLabelTextField label="Objetivos" field={meeting.field_objetivos} />
            <MultiLineLabelTextField label="Resultados" field={meeting.field_resultados} />
            <DownloadPDFField label="Minuta" field={meeting.field_minuta} />
        </>
    )
}

const DisplayTopics = ({ topic }) => {
    return (
        <>
            <SingleLineLabelTextField label="Título" field={topic.field_titulo} />
            <SingleLineLabelDateField label="Fecha" field={topic.field_fecha_tema} />            
            <MultiLineLabelTextField label="Detalle" field={topic.field_descripcion} />
        </>
    )
}

const DetailModal = ({ showModal, handleClose, entityArray, selectedIndex, setIndex }) => {
    if(showModal && entityArray) {
        var renderedDisplay = null
        var title = ""
        switch(entityArray[selectedIndex].type[0].target_id) {
            case "candidaturas":
                title = "Información de la candidatura"
                renderedDisplay = (
                    <DisplayNominations nomination={entityArray[selectedIndex]} />
                )
                break
            case "reuniones_realizadas":
                title = "Información de la reunión"
                renderedDisplay = (
                    <DisplayMeetings meeting={entityArray[selectedIndex]} />
                )
                break
            case "temas_estrategicos":
                title = "Detalle del tema"
                renderedDisplay = (
                    <DisplayTopics topic={entityArray[selectedIndex]} />
                )
                break
            default:
                break
        }
        return (
            <Modal show={showModal} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header> 
                <Modal.Body>
                    { renderedDisplay }
                </Modal.Body>
                <ModalFooter>
                    <ModalScrolling arrayCount={entityArray.length} setIndex={ setIndex } currentIndex={selectedIndex} />
                </ModalFooter>
            </Modal>
        )
    }
    return null
}
export default DetailModal