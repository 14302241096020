import { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NavigationBar from "../components/layout/NavigationBar";
import Cooperation from "../Pages/Country/Cooperation";
import Diplomacy from "../Pages/Country/Diplomacy";
import File from "../Pages/Country/File";
import Menu from "../Pages/Country/Menu";
import Policies from "../Pages/Country/Policies";
import Home from "../Pages/Home";

const AppRouter = ({ children, currentCountry, setCurrentCountryCallback, logoutCallback }) => {

    const [previousRoute, setPreviousRoute] = useState(null)
    const [previousRouteLabel, setPreviousRouteLabel] = useState(null)

    return (
        <Router>
            <NavigationBar previousRoute={ previousRoute } previousRouteLabel={ previousRouteLabel } logoutCallback={ logoutCallback }/>
            { children }
            <Switch>
                <Route exact path="/" render={ () => {return(<Home setPreviousRoute={ setPreviousRoute } setPreviousRouteLabel={ setPreviousRouteLabel }/>)} } />       
                <Route exact path="/country/:id" render={ () => {return(<Menu setCurrentCountryCallback={ setCurrentCountryCallback } setPreviousRoute={ setPreviousRoute } setPreviousRouteLabel={ setPreviousRouteLabel }/>)} } />
                <Route exact path="/country/:id/file" render={() => {return(<File country={ currentCountry } setPreviousRoute={ setPreviousRoute } setPreviousRouteLabel={ setPreviousRouteLabel }/>)}} />
                <Route exact path="/country/:id/policies" render={() => {return(<Policies country={ currentCountry } setPreviousRoute={ setPreviousRoute } setPreviousRouteLabel={ setPreviousRouteLabel }/>)}} />
                <Route exact path="/country/:id/diplomacy" render={() => {return(<Diplomacy country={ currentCountry } setPreviousRoute={ setPreviousRoute } setPreviousRouteLabel={ setPreviousRouteLabel }/>)}} />
                <Route exact path="/country/:id/cooperation" render={() => {return(<Cooperation country={ currentCountry } setPreviousRoute={ setPreviousRoute } setPreviousRouteLabel={ setPreviousRouteLabel }/>)}} />
            </Switch>
        </Router>
    )
}

export default AppRouter