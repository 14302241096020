import { Card } from 'react-bootstrap';
import AccordionItem from './Accordions';

const CurrentTopics = ({ topics }) => {

    

    const renderTopics = () => {
        return(
            topics.map((topic, i) => {
                return (
                    <Card body key={i}>
                        <div style={{ textAlign: "justify" }}>
                            { topic.value }
                        </div>                        
                    </Card>
                )
            })
        )
    }

    return(
        <AccordionItem title="Temas actuales" bg="filled" >
            {renderTopics()}
        </AccordionItem> 
    )
}

export default CurrentTopics