export const parseParagraphField = (field) => {

    var array = []

    field.forEach(element => {
        
        array.push(element.target_id)
    });
    
    return array
}