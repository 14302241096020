import { post } from './fetch';

export const getRecoverToken  = (email) => {
    var recoverEndpoint = `/user/lost-password?_format=json`
    var userInfo = {
        mail: email
    }
    return post(recoverEndpoint, userInfo)
} 

export const createNewPassword = (username, temp_pass, new_pass) => {
    var recoverEndpoint = `/user/lost-password-reset?_format=json`
    var userInfo = {
        name: username,
        temp_pass: temp_pass,
        new_pass: new_pass
    }
    return post(recoverEndpoint, userInfo)
}