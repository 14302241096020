import { useEffect } from "react";
import { Container } from "react-bootstrap";
import DiplomacyType from "../../components/DiplomacyType";
import CountryPageTitle from "../../components/layout/CountryPageTitle"
import { parseParagraphField } from "../../util/FieldParsers";

const Diplomacy = ({ country, setPreviousRoute, setPreviousRouteLabel }) => {
    useEffect(() => {
        setPreviousRoute(`/country/${country.nid[0].value}`)
        setPreviousRouteLabel('Diplomacia económica')
    }, [])  
    
    return (
        <>
            <CountryPageTitle titleField={country.title} imageField={country.field_image} />
            <Container>
                <DiplomacyType country={ country } groupName="Comercial" fieldPrioridad={country.field_prioridad_comercial} fieldLink={country.field_perfil_comercial} linkLabel="Perfil" linkText="SICOMEX" topicIdsArray={ parseParagraphField(country.field_temas_comercial) }/>
                <DiplomacyType country={ country } groupName="Científica, Tecnológica y de Innovación" fieldPrioridad={country.field_prioridad_cient_tec} fieldLink={country.field_enlace_sincyt} linkLabel="Perfil" linkText="SINCYT"  topicIdsArray={ parseParagraphField(country.field_temas_cient_tec) }/>
                <DiplomacyType country={ country } groupName="Atracción del Turismo" fieldPrioridad={country.field_prioridad_turismo} topicIdsArray={ parseParagraphField(country.field_temas_turismo) }/>  
                <DiplomacyType country={ country } groupName="Cultural y Deportiva" fieldPrioridad={country.field_prioridad_cultural_dep} topicIdsArray={ parseParagraphField(country.field_temas_cultura_dep) }/>
                <DiplomacyType country={ country } groupName="Académica" fieldPrioridad={country.field_prioridad_academica} topicIdsArray={ parseParagraphField(country.field_temas_academicos) }/>
                <DiplomacyType country={ country } groupName="Económico-Ambiental" fieldPrioridad={country.field_prioridad_econ_amb} topicIdsArray={ parseParagraphField(country.field_temas_econ_amb) }/>
            </Container>
        </>
        
    )
}

export default Diplomacy