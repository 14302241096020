const getItemFromStorage = async(itemName) => {
    try {
        const value = await localStorage.getItem(itemName)
        if(value !== null) {
            return value;
        } 
        return null;
    } catch(e) {
        return null;
    }
}

export const getToken = async () => {
    const token = await getItemFromStorage('@auth_token');
    if(token) { //Existe un token en el app
        const expiration = await getItemFromStorage('@auth_token_exp'); //Obtengo de almacenamiento fecha de expiración
        if(new Date(expiration * 1000) >= new Date()) { //Si la fecha de exp del token es mayor que hoy
            return token  
        } else {
            await removeToken() //Remuevo el token para generar uno nuevo+
        }
    }
    return null
}

export const setToken = async (token, expirationDate) => {
    try {
      await localStorage.setItem('@auth_token', token);
      await localStorage.setItem('@auth_token_exp', expirationDate);
    } catch (e) {
        console.log(e)
        return null;
    }
};

export const removeToken = async () => {
    try {
        await localStorage.removeItem('@auth_token')
        await localStorage.removeItem('@auth_token_exp')
    } catch (e) {
        return null;
    }
}