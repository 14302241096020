import { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import CountryPageTitle from "../../components/layout/CountryPageTitle";
import { MapField, PeopleImageField, SingleLineLabelTextField } from "../../util/FieldUtility";

const File = ({ country, setPreviousRoute, setPreviousRouteLabel }) => {
    useEffect(() => {
        setPreviousRoute(`/country/${country.nid[0].value}`)
        setPreviousRouteLabel('Ficha país')
    }, [])    
    return(
        <>
            <CountryPageTitle titleField={country.title} imageField={country.field_image} />
            <Container>
                <Row>
                    <MapField urlField={country.field_mapa} />
                </Row>
                <Row style={{ marginTop:20 }}>
                    <PeopleImageField  label="Jefe de Estado" nameField={country.field_nombre_jefe_estado} urlField={country.field_foto_jefe_estado} />
                    <PeopleImageField  label="Jefe de Gobierno" nameField={country.field_nombre_jefe_gobierno} urlField={country.field_foto_jefe_gobierno} />
                    <PeopleImageField  label="Ministro de Relaciones Exteriores" nameField={country.field_nombre_canciller} urlField={country.field_foto_de_jefe_canciller} />
                </Row>
                <SingleLineLabelTextField label="Nombre oficial" field={country.field_nombre_oficial} />
                <SingleLineLabelTextField label="Día nacional" field={country.field_dia_nacional} />
                <SingleLineLabelTextField label="Población" field={country.field_poblaci} />
                <SingleLineLabelTextField label="Superficie" field={country.field_superficia} />
            </Container>
        </>
    )
}

export default File