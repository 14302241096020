import { Col, Image, ListGroup, Row } from "react-bootstrap"
import "./CountryList.scss";

import { Link } from "react-router-dom";

const CountryListItem = ({ country, id }) => {
    return (
        <ListGroup.Item className="country-list--item">
            <Link to={ `/country/${country.nid}` }>
                <Row>
                    <Col xs={2}>
                        <Image src={country.field_image} roundedCircle className="country-list--image"/>
                    </Col>
                    <Col xs={10}>
                        <h5>{country.title}</h5>
                        <h6>{ country.field_nombre_oficial }</h6>
                    </Col>
                </Row>
            </Link>
        </ListGroup.Item>
    )
}

const CountryList = ({ countries }) => { 

    const renderCountryList = () => {
        return countries.map((country, i) => {
            return(
                <CountryListItem country={country}  key={i} id={i}/>
            )
        })
    }
    
    return (
        <ListGroup>
            {renderCountryList()}
        </ListGroup>
    )
} 

export default CountryList