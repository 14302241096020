import { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { getCountryList } from "../api/countries";
import LoadingSpinner from "../components/LoadingSpinner";
import CountryList from "../components/CountryList";

const loadCountryList = (search, setCountries, setLoading) => {
    getCountryList(search)
    .then(res => {
        setCountries(res)
        setLoading(false)
    })
    .catch(error => {
        console.log(error)
    })
}

const Home = ({ setPreviousRoute, setPreviousRouteLabel }) => {

    setPreviousRoute(null)
    setPreviousRouteLabel("SIIBCI")
    
    const [countries, setCountries] = useState(null)
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        loadCountryList(search, setCountries, setLoading)
    }, [search])

    if(loading) {
        return (
            <LoadingSpinner />
        )
    }

    return (
        <>            
            <Row>
                <Col md={{ span: 8, offset: 2 }} style={{ textAlign: "center", padding: 40 }}>
                    <Form>
                        <Form.Group controlId="search">
                            <Form.Control placeholder="Buscar" value={ search } onChange={ (event) => { setSearch(event.target.value) } }/>
                        </Form.Group>
                    </Form>
                </Col>
                <Col md={12}>
                    <CountryList countries={countries} />
                </Col>
            </Row>
        </>
    )
}

export default Home