import { useState } from "react";
import accordionCallback from "../util/AccordionCallbacks"
import SubEntityListItem from "../util/SubEntityList";
import AccordionItem from "./Accordions"
import DetailModal from "./DetailModal";

const Nominations = ({ nominationIds, country }) => {
    const [nominations, setNominations] = useState(null)
    const [hasLoaded, setHasLoaded] = useState(false)
    //Para modal Costa Rica
    const [showModal, setShowModal] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(null)

    const handleShowModal = (index) => {
        setSelectedIndex(index)
        setShowModal(true)
    }

    const handleCloseModal = () => {
        setSelectedIndex(null)
        setShowModal(false)
    }

    //Para modalOtroPais
    const [showModalOtro, setShowModalOtro] = useState(false)
    const [selectedIndexOtro, setSelectedIndexOtro] = useState(null)

    const handleShowModalOtro = (index) => {
        setSelectedIndexOtro(index)
        setShowModalOtro(true)
    }

    const handleCloseModalOtro = () => {
        setSelectedIndexOtro(null)
        setShowModalOtro(false)
    }

    const renderNominationsList = (list, label, handler, showModal, selectedIndex, handleCloseModal, setIndex) => {
        if(list.length > 0) {
            return (
                <>  
                    <DetailModal showModal={ showModal } handleClose={ handleCloseModal } entityArray={ list } selectedIndex={selectedIndex} setIndex={ setIndex }/>
                    <AccordionItem title={`Candidaturas de ${label}`} bg="blank" >
                        { list.map((nomination, i) => {
                            return(
                                <SubEntityListItem entity={nomination} key={i} text={ nomination.field_nombre[0].value } handleShowModal={ handler } itemIndex={i}/>
                            )
                        }) }
                    </AccordionItem>
                </>
            )
        }
        
        return null
    }

    const renderInnerAccordion = () => {
        if(!nominations) {
            if(!hasLoaded) {
                return (
                    <h5>Loading...</h5>
                )
            } else {
                return (
                    <h5>No hay reuniones para desplegar</h5>
                )
            }
            
        }

        var nominationsCR = []    
        var nominationsOther = []    
        
        nominations.forEach(element => {
            if(element.field_tipo_candidatura[0].value === 'cr') {
                nominationsCR.push(element)
            } else {
                nominationsOther.push(element)
            }
        });

        return (
            <>
                
                {renderNominationsList(nominationsOther, country.title[0].value, handleShowModalOtro, showModalOtro, selectedIndexOtro, handleCloseModalOtro, setSelectedIndexOtro)}
                {renderNominationsList(nominationsCR, 'Costa Rica', handleShowModal, showModal, selectedIndex, handleCloseModal, setSelectedIndex)}
            </>
        )
    }

    return (
        <>            
            <AccordionItem title="Candidaturas" bg="filled" callback={accordionCallback(nominationIds, nominations, setNominations, setHasLoaded)} >
                { renderInnerAccordion() }
            </AccordionItem>
        </>
    )
}

export default Nominations