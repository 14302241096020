import { getParagraphList } from "../api/paragraphs"

const orderArray = (originalArray, orderByArray) => {
    if(orderByArray) {
        var resultingArray = []
        orderByArray.forEach(function(key) {
            var found = false
            originalArray = originalArray.filter(function(item) {
                if(!found && item.id[0].value == key) {
                    resultingArray.push(item)
                    found = true
                    return false
                } else {
                    return true
                }
            })
        })

        return resultingArray
    }
    return null
}

const accordionCallback = (arrayIds, array, setArray, setHasLoaded) => {
    if(!array) {
        getParagraphList(arrayIds)
        .then((res) => {
            setArray(orderArray(res, arrayIds))      
            setHasLoaded(true)
        })
        .catch(error => {
            console.log(error)
        })
    }
}

export default accordionCallback