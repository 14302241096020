import { Col, Image, Row } from "react-bootstrap"
import "./CountryPageTitle.scss"

const CountryPageTitle = ({ imageField, titleField }) => {
    const imageUrl = imageField[0].url
    const title = titleField[0].value

    return (
        <Row className="country-page-title--container">
            <Col xs={{ span: 2, offset: 2 }}>
                <Image src={ imageUrl } roundedCircle className="country-page-title--image" style={{ objectFit:"cover" }}/>
            </Col>
            <Col xs="6">
                <h2>{ title }</h2>
            </Col>
        </Row>
    )
}

export default CountryPageTitle