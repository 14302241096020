import { Col, Container, Image, Row } from "react-bootstrap"
import logo from "../../static/img/logo-mrec.png";
import fondo from "../../static/img/fondo-siibci.png";

const LoginLayout = ({children}) => {
    return(
        <div style={{ backgroundImage:`url(${fondo})`, height:"100vh", backgroundSize:"cover", backgroundRepeat:"no-repeat" }}>            
            <Container>
                <Row className="justify-content-center align-items-center">
                    <Col xs={10} md={6} style={{ textAlign:"center", padding:"10px", borderRadius:"10px", backgroundColor: "white", marginTop:"80px", marginBottom:"80px" }}>
                        <Image src={logo} style={{ maxWidth:"280px", width:"100%" }}/>
                    </Col>
                </Row>
                {children}
            </Container>
        </div>
    )
}

export default LoginLayout