import { useState } from "react";
import { Card, Button, Collapse } from "react-bootstrap"
import "./Accordions.scss";

const AccordionItem = ({ title="Click Me!", bg="blank", callback=null, children }) => {

    const [open, setOpen] = useState(false);

    const Toggle = ({children}) => {
        const click = () => {            
            if(callback) {
                callback()
            }
            setOpen(!open)
        }
        return <Button onClick={ click } variant="link">{ children }</Button>
    }

    var backgroundColor = "white"
    var marginTop = "10px"

    if(bg==="filled") {
        backgroundColor= '#F6F6F6'
        marginTop = 30
    }

    return (
        <Card style={{ marginTop: marginTop }}>
            <Card.Header className="accordion--title-container" style={{ backgroundColor: backgroundColor }}>
                <Toggle>{ title }</Toggle>
            </Card.Header>
            <Collapse in={ open }>
                <Card.Body className="accordion--body-container">
                    { children }
                </Card.Body>
            </Collapse>
        </Card>
    )
}

export default AccordionItem