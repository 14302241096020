
import { useEffect, useState } from 'react';
import login from './api/auth';
import './App.scss';
import ForgotPassword from './components/ForgotPassword';
import LoginForm from './components/Login';
import AppRouter from './routing/Router';
import { getToken, removeToken, setToken } from './storage/local_storage';

function App() {

  /**
   * Possible loginStatus:
   * LOGIN_FULFILLED
   * LOGIN_PENDING
   * LOGIN_FORGOT_PW
   * 
   */
   const [loginStatus, setLoginStatus] = useState('') 
   const [isLoading, setIsLoading] = useState(true)

  const [currentCountry, setCurrentCountry] = useState(null)
  const [navigationArray, setNavigationArray] = useState(['/'])

  useEffect(() => {
    handleLoggedIn()
  }, [])

  const handleForgotPassword = (mostrarForgotPw=true) => {
    if(mostrarForgotPw) {
      setLoginStatus("LOGIN_FORGOT_PW")
      return
    }
    setLoginStatus("LOGIN_PENDING")
  }


  const handleLoggedIn = async () => {
    const token = await getToken()    
    if(token) {
      setLoginStatus('LOGIN_FULFILLED')
    } else {
      setLoginStatus('LOGIN_PENDING')
    }
    setIsLoading(false)
  }

  const handleLogin = async (userName, password) => {
    const loginResult = await login(userName, password)
    if(loginResult.status === 'OK') {
      console.log(loginResult)
      await setToken(loginResult.token, loginResult.expiration.toString())
      setLoginStatus('LOGIN_FULFILLED')
      return true
    }
    console.log(loginResult)
    setLoginStatus('LOGIN_PENDING')
    return false
  }

  const handleLogout = async () => {
    setLoginStatus('LOGIN_PENDING')
    await removeToken(null)
  }

  const renderSplashScreen = () => {
    return (
      <h1>Cargando</h1>
    )
  }

  const renderLoginScreen = () => {
    return (
      <div className="app-container">
        <LoginForm loginCallback={ handleLogin } forgotPassCallback={ handleForgotPassword }/>
      </div>
    )
  }

  const renderForgotPwScreen = () => {
    return (
      <div className="app-container">
        <ForgotPassword loginCallback={ handleLogin } returnCallback={ handleForgotPassword }/>
      </div>
    )
  }

  const renderApplication = () => {
    return (
      <div className="app-container">
        <AppRouter currentCountry={ currentCountry } setCurrentCountryCallback={ setCurrentCountry } navigationArray={navigationArray} setNavigationArray={ setNavigationArray } logoutCallback={ handleLogout }>
        </AppRouter>
      </div>
    )
  }

  const render = () => {
    if(!isLoading) {
      switch (loginStatus) {
        case 'LOGIN_PENDING':
          return renderLoginScreen()
        case 'LOGIN_FORGOT_PW':
          return renderForgotPwScreen()
        default:
          return renderApplication()
      }
    }
    return renderSplashScreen()
  }

  return (
    render()
  );
}

export default App;
