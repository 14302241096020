import { useState } from 'react';
import { SingleLineLabelLinkField, SingleLineLabelTextField } from '../util/FieldUtility';
import AccordionItem from "./Accordions"
import SubEntityListItem from '../util/SubEntityList';
import accordionCallback from '../util/AccordionCallbacks';
import DetailModal from './DetailModal';

const DiplomacyType = ({ country, groupName, fieldPrioridad, fieldLink=null, linkLabel=null, linkText=null, topicIdsArray=null }) => {

    const [topics, setTopics] = useState(null)
    const [hasLoaded, setHasLoaded] = useState(false)
    //Para modal
    const [showModal, setShowModal] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(null)

    const handleShowModal = (index) => {
        setSelectedIndex(index)
        setShowModal(true)
    }

    const handleCloseModal = () => {
        setSelectedIndex(null)
        setShowModal(false)
    }

    const renderTemas = () => {
        if(!topics) {
            if(!hasLoaded) {
                return (
                    <h5>Loading...</h5>
                )
            } else {
                return (
                    <h5>No hay información para desplegar</h5>
                )
            }            
        }

        return (
            topics.map((topic, i) => {
                return(
                    <SubEntityListItem entity={topic} key={i} text={ topic.field_titulo[0].value } handleShowModal={ handleShowModal } itemIndex={i} />
                )
            })
        )
    }

    return (
        <>
            <AccordionItem title={groupName} bg="filled" >
                <SingleLineLabelTextField field={fieldPrioridad} label="Prioridad" />
                <SingleLineLabelLinkField field={fieldLink} label={linkLabel} linkText={linkText} />
                <DetailModal showModal={ showModal } handleClose={ handleCloseModal } entityArray={ topics } selectedIndex={selectedIndex} setIndex={ setSelectedIndex }/>
                <AccordionItem title="Temas estratégicos" bg="blank" callback={accordionCallback(topicIdsArray, topics, setTopics, setHasLoaded)}>
                    {renderTemas()}
                </AccordionItem>
            </AccordionItem>
        </>
    )

}

export default DiplomacyType