import { useEffect } from "react";
import { Card, Container } from "react-bootstrap"
import AccordionItem from "../../components/Accordions"
import CountryPageTitle from "../../components/layout/CountryPageTitle"


const Cooperation = ({ country, setPreviousRoute, setPreviousRouteLabel }) => {
    useEffect(() => {
        setPreviousRoute(`/country/${country.nid[0].value}`)
        setPreviousRouteLabel('Cooperación internacional')
    }, []) 
    
    const TopicAccordion = ({ title, field }) => {

        const renderElements = () => {
            return(
                field.map((element, i) => {
                    return (
                        <Card body key={i}>
                            <div style={{ textAlign: "justify", whiteSpace:"pre-line" }}>
                                { element.value }
                            </div>                        
                        </Card>
                    )
                })
            )
        }
    
        return (
            <AccordionItem title={ title } bg="filled" >
                { renderElements() }
            </AccordionItem>
        )
    }

    return (
        <>
            <CountryPageTitle titleField={country.title} imageField={country.field_image} />
            <Container>
                <TopicAccordion title="Hechos recientes" field={country.field_hechos_recientes} />
                <TopicAccordion title="Instrumentos de cooperación" field={country.field_acuerdos_memorandums} />
                <TopicAccordion title="Proyectos de Cooperación" field={country.field_proyectos_cooperacion} />
                <TopicAccordion title="Becas" field={country.field_becas} />
            </Container>
        </>
    )

}

export default Cooperation