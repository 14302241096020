import { useState } from "react"
import { Col, Form, Row, Button, Modal, ButtonGroup, Alert } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faKey, faUnlockAlt } from "@fortawesome/free-solid-svg-icons"
import LoginLayout from "./layout/LoginLayout";
import { createNewPassword, getRecoverToken } from "../api/recoverpw";

const ForgotPassword = ({ loginCallback, returnCallback }) => {
    const [userName, onChangeUserName] = useState('')
    const [password, onChangePassword] = useState('')
    const [confPassword, onChangeConfPassword] = useState('')
    const [tempPassword, onChangeTempPassword] = useState('')
    const [showPasswordFields, setShowPasswordFields] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')
    const [showAlert, setShowAlert] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [alertType, setAlertType] = useState('success')

    const formHasErrors = () => {
        var errores = []
        var hasErrors = false

        if(!userName) {
            errores.push("Debe digitar su correo electrónico")
            hasErrors = true
        }

        if(!tempPassword) {
            errores.push("Debe digitar la contraseña temporal que le fue enviada al correo electrónico")
            hasErrors = true
        }

        if(!password || !confPassword) {
            errores.push("Debe digitar la contraseña y la contraseña de confirmación")
            hasErrors = true
        } else {
            if(password !== confPassword) {
                errores.push("la contraseña y la confirmación no coinciden")
                hasErrors = true
            }
        }

        if(hasErrors) {
            var errorMessage = "Por favor corrija los siguientes errores:\n"
            errores.forEach(error => {
                errorMessage += error + '.\n'
            });
            setAlertMessage(errorMessage)
            setAlertType('danger')
            setShowAlert(true)
        }

        return hasErrors
    }

    const sendNewPasswordEmail = async () => {
        
        var response = await getRecoverToken(userName)
        if(response.status === 'OK') {
            setAlertType('success')
            setShowPasswordFields(true)
            setShowAlert(true)
        } else {            
            setAlertType('danger')
        }
        setShowAlert(true)
        setAlertMessage(response.message)
    }

    const submit = async () => {
        if(!formHasErrors()) {
            var response = await createNewPassword(userName, tempPassword, password)
            if(response.status === 'OK') {
                setShowModal(true)
            } else {
                setAlertType('danger')
                setShowAlert(true)
                setAlertMessage(response.message)
            }
        }
    }

    const login = async () => {
        await loginCallback(userName, password)
    }

    const NewPasswordForm = () => {
        if(showPasswordFields) {
            return (
                <>
                    <Form.Group controlId="temppassword">
                        <Form.Control placeholder="Contraseña temporal" value={ tempPassword } onChange={ (event) => { onChangeTempPassword(event.target.value) } }/>
                        <Form.Text className="text-muted">
                            Por favor ingrese la <strong>contraseña temporal</strong> enviada al correo electrónico
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="password">
                        <Form.Control type="password" placeholder="Nueva contrasña" value={ password } onChange={ (event) => { onChangePassword(event.target.value) } }/>
                    </Form.Group>
                    <Form.Group controlId="confpassword">
                        <Form.Control type="password" placeholder="Confirme su nueva contraseña" value={ confPassword } onChange={ (event) => { onChangeConfPassword(event.target.value) } }/>
                        <Form.Text className="text-muted">
                            Por favor ingrese dos veces su <strong>nueva contraseña</strong>
                        </Form.Text>
                    </Form.Group>
                    <Modal show={showModal} onHide={() => { showModal(false) }} aria-labelledby="contained-modal-title-vcenter" centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Contraseña recuperada</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Su contraseña se ha reestablecido correctamente.
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="success" onClick={login}>
                            <FontAwesomeIcon icon={faUnlockAlt}/> Continuar
                        </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            )
        }
        return null
    }

    const SubmitButton = () => {
        if(showPasswordFields) {
            return (
                <Button variant="light" onClick={() => { submit() }} style={{ minWidth:'150px', color: "#1C3664", fontWeight: "bold", backgroundColor: "#E8E8E8" }}>
                    <FontAwesomeIcon icon={faKey} style={{ marginRight:"10px" }}/>
                    Cambiar contraseña
                </Button>
            )
        }
        return null
    }

    return (        
        <LoginLayout>
            <Row className="justify-content-center align-items-center">
                <Col xs={10} md={6} style={{ backgroundColor: "white", padding:"20px", borderRadius:"10px" }}>
                    <Col xs={12}>
                        <Form className="form">
                            <h3 className="text-center" style={{ color:"#1C3664", fontWeight:"bold", marginBottom:"40px" }}>Recuperación de contraseña</h3>
                            <Alert show={showAlert} variant={alertType} onClose={() => setShowAlert(false)} dismissible>
                                <p>{alertMessage}</p>
                            </Alert>
                            <Form.Group controlId="username">
                                <Form.Control placeholder="Email" value={ userName } onChange={ (event) => { onChangeUserName(event.target.value) } }/>
                                <Form.Text className="text-muted">
                                    Por favor indique su correo electrónico registrado en el sistema.
                                </Form.Text>
                            </Form.Group>
                            { NewPasswordForm() }                            
                            <div style={{ textAlign:"center" }}>
                                <div style={{ marginBottom:"20px" }}>
                                    <ButtonGroup vertical>
                                        <SubmitButton />
                                        <Button variant="light" onClick={() => { sendNewPasswordEmail() }} style={{ minWidth:'150px', color: "#1C3664", fontWeight: "bold", backgroundColor: "#E8E8E8" }}>
                                            <FontAwesomeIcon icon={faEnvelope} style={{ marginRight:"10px" }}/>
                                            Enviar correo
                                        </Button>
                                        <Button variant="light" style={{ minWidth:'150px', color: "#1C3664", fontWeight: "bold", backgroundColor: "#E8E8E8" }} onClick={() => { returnCallback(false) }}>
                                            Regresar
                                        </Button>
                                    </ButtonGroup>
                                </div>              
                            </div>
                        </Form>
                    </Col>
                </Col>
            </Row>
        </LoginLayout>
    )
} 

export default ForgotPassword