import { Badge, Button, ButtonGroup, Col, Row } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStepBackward , faStepForward } from "@fortawesome/free-solid-svg-icons"

const ModalScrolling = ({ previousText = "Anterior", nextText="Siguiente", setIndex, arrayCount, currentIndex  }) => {

    const renderPreviousButton = () => {
        if(currentIndex > 0) {
            return (
                <Button variant="light" className="modal-scrolling--button" style={{ color:"#1C3664" }} 
                onClick={() => {
                    setIndex(currentIndex - 1)
                }}>
                    <Badge variant="success">{currentIndex}</Badge>
                    <FontAwesomeIcon icon={faStepBackward} />
                    <span>{previousText}</span>
                </Button>
            )
        }
    }

    const renderNextButton = () => {
        if(currentIndex < arrayCount-1) {
            return (
                <Button variant="light" className="modal-scrolling--button" style={{ color:"#1C3664" }}
                onClick={() => {
                    setIndex(currentIndex + 1)
                }}>                    
                    <FontAwesomeIcon icon={faStepForward} />
                    <span>{nextText}</span>
                    <Badge variant="success">{arrayCount - 1 - currentIndex}</Badge>
                </Button>
            )
        }
    }
    if(arrayCount > 1) {
        return (
            <Row>
                <Col xs={12} className="modal-scrolling--container">
                    <ButtonGroup aria-label="scrolling">
                        { renderPreviousButton() }
                        { renderNextButton() }
                    </ButtonGroup>
                </Col>
            </Row>
        )
    }

    return null

}

export default ModalScrolling