import React from 'react';
import { Col, ListGroup, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const SubEntityListItem = ({ entity, text, itemIndex, handleShowModal }) => {
    if(entity) {
        return(
            <ListGroup.Item>
                <Row onClick={ ()=>{handleShowModal(itemIndex)} } style={{ cursor:"pointer" }}>
                    <Col xs={10}>
                        <h6 style={{ textAlign: 'justify', color:"#666666" }}>{ text }</h6>
                    </Col>
                    <Col xs={2} style={{ textAlign: 'right', color:"#666666" }}>
                        <FontAwesomeIcon icon={ faArrowRight } />
                    </Col>
                </Row>
            </ListGroup.Item>
        )
    }
    return null
}

export default SubEntityListItem