import { Image, Row, Col } from "react-bootstrap"
import { format } from "date-fns";

import "./FieldUtility.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faFileDownload } from "@fortawesome/free-solid-svg-icons"

export const SingleLineLabelTextField = ({ label, field }) => {
    if(field) {
        if(field.length > 0) {
            const fieldText = field[0].value
            return (
                <Row style={{ marginTop: "20px" }}>
                    <Col xs={4}>
                        <span className="field--label">{ label }</span>
                    </Col>
                    <Col xs={8}>
                        <span className="field--text">{fieldText}</span>
                    </Col>
                </Row>
            )
        }
    }

    return null
}

export const SingleLineLabelDateField = ({ label, field }) => {
    if(field) {
        if(field.length > 0) {
            const fieldDate = field[0].value
            return (
                <Row style={{ marginTop: "20px" }}>
                    <Col xs={4}>
                        <span className="field--label">{ label }</span>
                    </Col>
                    <Col xs={8}>
                        <span className="field--text">
                            { format(new Date(fieldDate),  "dd-MM- yyyy") }
                        </span>
                    </Col>
                </Row>
            )
        }
    }

    return null
}

export const SingleLineLabelLinkField = ({ label, field, linkText }) => {
    if(field) {
        if(field.length > 0) {
            const fieldUrl = field[0].uri
            return (
                <Row style={{ marginTop: "20px" }}>
                    <Col xs={4}>
                        <span className="field--label">{ label }</span>
                    </Col>
                    <Col xs={8}>
                        <a href={fieldUrl} target="_blank" rel="noopener noreferrer" className="field--link">
                            <FontAwesomeIcon icon={faLink} />
                            { linkText }
                        </a>                        
                    </Col>
                </Row>
            )
        }
    }

    return null
}

export const MultiLineLabelTextField = ({ label, field }) => {
    if(field) {
        if(field.length > 0) {
            const fieldHtml = field[0].processed
            return (
                <>
                    <Row style={{ marginTop: "20px", marginBottom: "10px" }}>
                        <Col xs={12}>
                            <span className="field--label">{ label }</span>
                        </Col>  
                        <Col xs={12}>
                            <div className="field--html-text" dangerouslySetInnerHTML={{__html: fieldHtml}}></div>
                        </Col>             
                    </Row>
                </>
            )
        }
    }

    return null
}

export const PeopleImageField = ({ label, urlField, nameField }) => {    
    if(urlField) {
        if(urlField.length > 0 && nameField.length > 0) {
            const url = urlField[0].url
            const name = nameField[0].value

            return (
                <Col style={{ display: "flex", flexDirection:"column", alignItems: "center"}}>
                    <Image src={url} style={{width: 90, height: 130, borderRadius: 10, objectFit:"cover"}}/>
                    <span className="field--people-header">{ label }</span>
                    <span className="field--text">{ name }</span>
                </Col>
            )
        }
    }    
    return null
}

export const MapField = ({ urlField }) => {
    if(urlField) {
        if(urlField.length > 0) {
            const url = urlField[0].url
            return (
                <Col  style={{ display: "flex", flexDirection:"column", alignItems: "center"}}>
                    <Image src={url} style={{width: "100%", height:"auto", maxWidth:"400px", borderRadius: 20}}/>
                </Col>                
            )
        }
    }
    return null
}

export const DownloadPDFField = ({ label, field, navigation, country }) => {
    if(field) {
        if(field.length > 0) {
            const url = field[0].url
            return (
                <Row style={{ marginTop: "20px" }}>
                    <Col xs={4}>
                        <span className="field--label">{ label }</span>
                    </Col>
                    <Col xs={8}>
                        <a href={url} target="_blank" rel="noopener noreferrer" className="field--link">
                            <FontAwesomeIcon icon={faFileDownload} />
                            Ver archivo
                        </a>                        
                    </Col>
                </Row>
            )
        }
    }

    return null
}